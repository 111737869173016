import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer2';
import { Helmet } from 'react-helmet';
import './About.css';

const randomNumber = Math.floor(Math.random() * 5) + 10;
const bnr = require('./../../images/banner/bnr' + randomNumber + '.jpg');

class About extends Component {
    render() {
        return (
            <div className="page-wraper">
                <Header />

                <Helmet>
                    <title>About Us: Open To Work Remote - Your Global Remote Job Board Platform</title>
                    <meta name="description" content="Open To Work Remote is a specialized job board connecting talented professionals with remote opportunities worldwide. Discover our mission and join our community of remote workers." />
                    <link rel="canonical" href="https://www.opentoworkremote.com/about" />
                </Helmet>

                <div className="page-content">
                    <div className="dez-bnr-inr jobs-category overlay-black-middle" style={{backgroundImage: "url(" + bnr + ")"}}>
                        <div className="container">
                            <div className="dez-bnr-inr-entry text-center">
                                <h1 className="text-white">About Open To Work Remote</h1>
                                <p className="text-white lead">Connecting Global Talent with Remote Opportunities Since 2022</p>
                            </div>
                        </div>
                    </div>	

                    <div className="section-full content-inner-2 bg-white">
                        <div className="container">
                            {/* Vision and Mission Statements */}
                            <div className="row align-items-center m-b50">
                                <div className="col-md-12 col-lg-6 m-b30">
                                    <div className="our-vision">
                                        <h2 className="h2">Our Vision</h2>
                                        <div className="vision-content">
                                            <p className="lead m-b20">We envision a world where talent knows no borders and great opportunities are accessible to everyone, regardless of their location.</p>
                                            <ul className="list-check primary m-b30">
                                                <li>Empowering remote professionals worldwide</li>
                                                <li>Breaking down geographical barriers</li>
                                                <li>Creating inclusive work opportunities</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 m-b30">
                                    <div className="our-mission">
                                        <h2 className="h2">Our Mission</h2>
                                        <div className="mission-content">
                                            <p className="lead m-b20">At OpenToWorkRemote, we're dedicated to making remote job searching simple and effective. We connect talented professionals with companies that embrace remote work culture.</p>
                                            <ul className="list-check primary m-b30">
                                                <li>Simplifying remote job search</li>
                                                <li>Connecting global talent with opportunities</li>
                                                <li>Building a remote-first community</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            {/* Call to Action Button after Vision/Mission */}
                            <div className="text-center m-b50">
                                <Link to={"/"} className="site-button radius-xl outline button-lg">
                                    <i className="fa fa-search m-r10"></i>Find Remote Jobs
                                </Link>
                            </div>

                            {/* Key Features */}
                            <div className="row features-section">
                                <div className="col-lg-4 col-md-6 m-b30">
                                    <div className="feature-container text-center p-4">
                                        <div className="icon-lg m-b20">
                                            <i className="fa fa-search"></i>
                                        </div>
                                        <h4>Aggregated Jobs</h4>
                                        <p>We gather remote jobs from multiple platforms and ATS systems in one place</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 m-b30">
                                    <div className="feature-container text-center p-4">
                                        <div className="icon-lg m-b20">
                                            <i className="fa fa-globe"></i>
                                        </div>
                                        <h4>Global Reach</h4>
                                        <p>Access opportunities from anywhere in the world</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 m-b30">
                                    <div className="feature-container text-center p-4">
                                        <div className="icon-lg m-b20">
                                            <i className="fa fa-refresh"></i>
                                        </div>
                                        <h4>Daily Updates</h4>
                                        <p>Fresh remote opportunities updated every day</p>
                                    </div>
                                </div>
                            </div>

                            {/* Video Section */}
                            <div className="section-full content-inner-2">
                                <div className="row justify-content-center">
                                    <div className="col-lg-8 text-center">
                                        <h3>Watch Our Platform Introduction</h3>
                                        <p className="text-muted small">
                                            <i className="fa fa-language m-r5"></i>
                                            Video is in Spanish with English subtitles available
                                        </p>
                                        <div className="video-container m-t20 m-b50">
                                            <div className="embed-responsive embed-responsive-16by9">
                                                <iframe 
                                                    className="embed-responsive-item"
                                                    src="https://www.youtube.com/embed/y4WZgWO7MsI"
                                                    title="OpenToWorkRemote Introduction"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen>
                                                </iframe>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Benefits Grid */}
                            <div className="row">
                                <div className="col-lg-6 m-b30">
                                    <div className="benefit-box p-4">
                                        <h4>For Job Seekers</h4>
                                        <ul className="list-check primary">
                                            <li>Access to quality remote positions</li>
                                            <li>User-friendly search experience</li>
                                            <li>Regular job updates</li>
                                            <li>Verified company listings</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 m-b30">
                                    <div className="benefit-box p-4">
                                        <h4>Why Remote Work?</h4>
                                        <ul className="list-check primary">
                                            <li>Better work-life balance</li>
                                            <li>Access to global opportunities</li>
                                            <li>Increased productivity</li>
                                            <li>Freedom to work from anywhere</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* Call to Action */}
                            <div className="text-center m-t50">
                                <h3>Ready to Start Your Remote Journey?</h3>
                                <p className="lead">Join thousands of professionals finding their dream remote jobs</p>
                                <Link to={"/"} className="site-button m-t20 button-lg">
                                    <i className="fa fa-search m-r10"></i> Explore Opportunities
                                </Link>
                            </div>

                            {/* Footer Note */}
                            <div className="text-center m-t50">
                                <hr />
                                <p className="m-t20">
                                    <span role="img" aria-label="Wave">👋️</span> 
                                    <b> From Mauro and the OpenToWorkRemote team</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
};

export default About;
