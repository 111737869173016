import React from 'react';

const Pagination = ({ page, pages, prevPage, nextPage }) => {
    return (
        <div className="pagination-bx mb-0">
            <ul className="pagination justify-content-center align-items-center mb-0">
                {prevPage ? (
                    <li className="page-item" title="Previous page (Left arrow key)">
                        <a className="page-link navigation-link" href={`${prevPage}`}>
                            <i className="ti-arrow-left"></i>
                        </a>
                    </li>
                ) : (
                    <li className="page-item invisible">
                        <span className="page-link navigation-link">
                            <i className="ti-arrow-left"></i>
                        </span>
                    </li>
                )}
                {(page !== 0 && pages !== 0) && page && (
                    <>
                        <li className="page-info">
                            <div className="pagination-counter">
                                <span className="">Page</span>
                                <span className="current" style={{ color: '#363030', fontWeight: 'bold' }}>{page}</span>
                                <span className="separator">/</span>
                                <span className="total">{pages}</span>
                            </div>
                        </li>
                    </>
                )}
                {nextPage ? (
                    <li className="page-item" title="Next page (Right arrow key)">
                        <a className="page-link navigation-link" href={`${nextPage}`}>
                            <i className="ti-arrow-right"></i>
                        </a>
                    </li>
                ) : (
                    <li className="page-item invisible">
                        <span className="page-link navigation-link">
                            <i className="ti-arrow-right"></i>
                        </span>
                    </li>
                )}
            </ul>
            <div className="keyboard-hint text-center mt-2">
                Use ← → arrow keys to navigate
            </div>
        </div>
    );
};

export default Pagination; 